import { createContext, useState } from "react";



const LocationContext = createContext();
const LocationContextProvider = ({children}) =>{

    const [location, setLocation] = useState("");

    return (
        <LocationContext.Provider value={{location,setLocation}}>
            {children}
        </LocationContext.Provider>
    )
}

export { LocationContext, LocationContextProvider };
