import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";


const UsersView = ()=>{
    const endpoint = "administration/users";
    const [t,i18n] = useTranslation("global");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [result,setResult] = useState({});
    const [title,setTitle] = useState("");
    const nav = useNavigate();

    const {id} = useParams();
    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setTitle(data.person_firstname+' '+data.person_surname);
                setResult(data);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    },[])

    const back = ()=>{
        nav(-1);
    }

    return(
        <div className="app">
            <div className="container bold-container-content">
                <span className="material-symbols-outlined absolute" role="button" onClick={back}>arrow_back</span>
                <div className="title-section-forms">
                    {title}
                </div>

                <div className="form-title">
                    {t("user")}
                </div>

                <div className="row">
                    <div className="col-md-6 mb-2">
                        <dt>{t('user')}:</dt>
                        <dd>{result.user_username}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('profile')}</dt>
                        <dd>{result.profile_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('locationComponent.locationName')}</dt>
                        <dd>{(result.location_name || 'N/A')}</dd>
                    </div>
                </div>

                <div className="footer-section-view row"></div>

                <div className="form-title">
                    {t("personComponent.personInformation")}
                </div>

                <div className="row">
                    <div className="col-md-6 mb-2">
                        <dt>{t('fullname')}</dt>
                        <dd>{result.person_firstname} {result.person_secondname} {result.person_surname} { result.person_secondsurname}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('email')}</dt>
                        <dd>{result.person_email}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('phone')}</dt>
                        <dd>{result.person_phone}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('personComponent.birthdate')}</dt>
                        <dd>{result.person_birthdate}</dd>
                    </div>
                </div>

                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {result.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(result.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {result.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {result.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {result.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(result.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default UsersView;