import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";

const CashoutView = ()=>{
    const endpoint = "cashout";
    const handlerApp = HandlerApp();
    const api = useFetch();
    const nav = useNavigate();

    const [t,i18n] = useTranslation("global");
    const [data,setData] = useState({});
    const [title,setTitle] = useState("");
    

    const {id} = useParams();
    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setTitle(data.category_name);
                setData(data);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    },[])

    const back = ()=>{
        nav(-1);
    }

    return(
        <div className="app">
            <div className="container bold-container-content">
                <span className="material-symbols-outlined absolute" role="button" onClick={back}>arrow_back</span>
                <div className="title-section-forms">
                    {title}
                </div>
                <div className="row np-row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4 mb-2">
                                <dt>{t('name')}</dt>
                                <dd>{data.machine_id}</dd>
                            </div>
                            <div className="col-md-4 mb-2">
                                <dt>{t('cashOut.cashout')}</dt>
                                <dd>{handlerApp.formatCurrency(data.cashout_out)}</dd>
                            </div>
                            <div className="col-md-4 mb-2">
                                <dt>{t('cashOut.date')}</dt>
                                <dd>{handlerApp.getDate(data.cashout_date) }</dd>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CashoutView;