import { Alert, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import env from "../../env-local";
import "../theme/Barberlytics/css/login.css";
import logo from "../../assets/img/logo.png";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const ForgotNumber = () => {
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState();

    const [t,i18n] = useTranslation("global");
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [language,setLanguage] = useState("");
    const [sendEmail, setSendEmail] = useState(false);

    useEffect(()=>{
        let lang = localStorage.getItem("language");
        if(lang == "" || lang == null){
            setLanguage("es")
        }else{
            setLanguage(lang);
        }
    },[])

    useEffect(()=>{
        i18n.changeLanguage(language)
        localStorage.setItem('language',language);
    },[language])


    const nav = useNavigate();

    const handleFormForgotNumber = (data) => {
        let body = {
            ...data
        }
        setLoader(true);
        axios.post(env.urlBackend + '/forgot-number', body)
        .then(response => {
            if(response.data.success){
                setLoader(false);
                nav('/authenticate');
            }else{
                setLoader(false)
                setError(t(error.response.data.message));
            }
        }).catch(error => {
            setLoader(false);
            setError(t(error.response.data.message));
        })
        // setLoader(true);
        // setSendEmail(true);
        // setLoader(false);
    }

    return (
        <>
            <div className="language-login">
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="material-symbols-outlined">language</span>
                    </button>
                    <ul className="dropdown-menu">
                        <li><button className={language === "es" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("es")}}>ES</button></li>
                        <li><button className={language === "en" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("en")}}>EN</button></li>
                    </ul>
                </div>
            </div>
            { !sendEmail && (
                <>
                    <div className="d-flex justify-content-center bg-login">
                        <div className="login-container">
                            <div className="section-login login">
                                <img src={logo} alt="logo-brava" className="logo-login"/>
                                <h1 className="title-login">{t('loginComponent.enterYourEmail')}</h1>
                                <p className="light">{t('loginComponent.sendInfoEmail')}</p>
                                <form action="/" onSubmit={handleSubmit(handleFormForgotNumber)}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <TextField label={t('email')} type="email" variant="outlined" size="small" 
                                                {...register("email",{ 
                                                    required: true, 
                                                    maxLength: 80, 
                                                    pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,8}$/ 
                                                })}
                                                error={errors.email != null}
                                                helperText={
                                                    errors.email?.type === 'required' ? t("errors.required"): 
                                                    errors.email?.type === 'pattern' ? t("errors.pattern") : 
                                                    errors.email?.type === 'maxLength' ? t("errors.maxLength") : ""}
                                            />
                                        </div>
                                    </div>
                                    {error &&
                                        <Alert className="mb-3" severity="error">{error}</Alert>
                                    }
                                    <center>
                                        <button className="btn btn-secondary-green w-100 mb-2" type="submit">{t("send")}</button>
                                        <Link to={"/authenticate"} className="btn">{t('back')}</Link>
                                    </center>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            )}
            { sendEmail && (
                <>
                    <div className="d-flex justify-content-center bg-login">
                        <div className="login-container">
                            <div className="section-login login">
                                <img src={logo} alt="logo-brava" className="logo-login"/>
                                <h1 className="title-login">{t("loginComponent.checkYourEmail")}</h1>
                                <p className="light">We send you an email to egarcia@mail.com, follow the instructions and reset your phone number.</p>
                                <p className="light">if you still don´t see it, check yor spam email.</p>
                                <center>
                                    <button className="btn btn-secondary-green w-100 mb-2">mail sent?</button>
                                    <Link to={"/authenticate/contact"} className="btn btn-light w-100 mt-3">I can't get the number</Link><br/>
                                    <Link to={"/authenticate"} className="btn">{t('back')}</Link><br/><br/>
                                </center>
                            </div>
                        </div>
                    </div>
                </>
            )}
            
            {
                loader &&
                <>
                    <div className="content-loader">
                        <CircularProgress />
                    </div>
                </>
            }
        </>
    )
}

export default ForgotNumber;