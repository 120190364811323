import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_en from "../../../../translate/en/global.json";
import global_es from "../../../../translate/es/global.json";
import { Outlet } from "react-router-dom";

const Container=()=>{
    i18next.init({
        interpolation: {escapeValue: false},
        lng: "es",
        resources: {
            es: {
                global: global_es
            },
            en: {
                global: global_en
            }
        }
    })

    return(
        <I18nextProvider i18n={i18next}>
            <Outlet />
        </I18nextProvider>
    )
}


export default Container;