
import { useEffect, useState, useMemo, useContext } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

const Users = () => {
    const endpoint = "administration/users";
    const [t,i18n] = useTranslation("global");
    const title = t('users');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);

    const handlerApp = HandlerApp();
    
    const [permissions,setPermissions] = useState([]);
    
    const {app} = useContext(AppContext);

    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
                deleteRow(id, action);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
        }
    }
    
    const optionRequest = (id, action) => {
        const confirmButtonColor = action === 'active' ? 'green' : 'red';
        const confirmationMessage = action === 'active' ? t('activateAlert.activateRecord') : t('activateAlert.deactivateRecord');
        Swal.fire({
            title: confirmationMessage,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: t('activateAlert.confirm'),
            confirmButtonColor: confirmButtonColor,
            cancelButtonText: t('cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                const status = action === 'active' ? 1 : 0;
                const body = { action: 'updateStatus', status };
                handlerApp.setLoader(true);
                api.put(endpoint + "/" + id, body)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            sendRequest();
                        } else {
                            handlerApp.handlerResponse(response);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                    })
                    .finally(() => {
                        handlerApp.setLoader(false);
                    });
            }
        });
    };

    const deleteRow = (id) => {
        Swal.fire({
            title: `${t('deleteAlert.deleteRecord')}`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `${t('deleteAlert.delete')}`,
            confirmButtonColor: "red",
            cancelButtonText: `${t('cancel')}`
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                api.deleteMethod(endpoint + '/' + id)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            sendRequest();
                        } else {
                            handlerApp.handlerResponse(response);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                    })
                    .finally(() => {
                        handlerApp.setLoader(false);
                    });
            }
        });
    };

    const rowSelected = (row)=>{
        console.log(console.log(row));
    }

    const columns = [
        {
            id: 'user_id',
            name: 'id',
            selector: row => row.user_id,
            sortable: true,
            width: "70px"
        },
        {
            id: 'user_username',
            name: t('username'),
            selector: row => row.user_username,
            sortable: true
        },
        {
            id: 'person_fullname',
            name: t('name'),
            selector: row => row.person_fullname,
            sortable: true,
        },
        {
            id: 'person_email',
            name: t('email'),
            selector: row => row.person_email,
            sortable: true
        },
        {
            id: 'profile_name',
            name: t('profileName'),
            selector: row => row.profile_name,
            sortable: true,
        },
        {
            id: 'location_name',
            name: t('locationComponent.locationName'),
            selector: row => row.location_name || 'N/A',
            sortable: true,
        },
        {
            name: t("action"),
            cell:(row) => { return handlerApp.actions(row,permissions,'user',handlerAction)},
            // cell:(row) => { return actions(row)},
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }
    
    useEffect(() => {
        sendRequest();
    }, [])

    useEffect(()=>{
        setPermissions(app.permissions[endpoint])
    },[app])

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.user_id && item.user_id.toString().toLowerCase().includes(filterText.toLowerCase())
                || item.people_name.toString().toLowerCase().includes(filterText.toLowerCase())
                || item.person_email.toString().toLowerCase().includes(filterText.toLowerCase())
                || item.profile_name?.toString().toLowerCase().includes(filterText.toLowerCase())
                || item.location_name?.toString().toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div className="app container">
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <h3>{title}</h3>
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            <button className="btn-table-actions" onClick={()=>{handlerAction(null,'create')}}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                            <button className="btn-table-actions" onClick={()=>{handlerAction(null,'refresh')}}>
                                <span className="material-symbols-outlined">autorenew</span>
                            </button>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        // subHeaderComponent={subHeaderComponentMemo}
                        selectableRows
                        onSelectedRowsChange={rowSelected}
                        persistTableHead
                    />
                </div>
            </div>
        </div>
    )
}

export default Users;