import { TextField, IconButton, InputAdornment } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import { DatePicker } from "@mui/x-date-pickers";
import BoldSelect from "../../../utils/BoldSelect";
import HandlerApp from "../../../utils/handlerApp";
import InputMask from 'react-input-mask';
import moment from "moment/moment";
import { Visibility, VisibilityOff } from "@mui/icons-material";


const UsersForms = () => {

    const endpoint = "administration/users";
    const [t, i18n] = useTranslation("global");

    const [title, setTitle] = useState(t('user'));
    const api = useFetch();
    const [data, setData] = useState([]);

    const nav = useNavigate();
    const { id } = useParams();
    const [readonly, setReadOnly] = useState(id ? true : false);

    const [birthdate, setBirthdate] = useState(null);
    const [showLocation, setShowLocation] = useState(false);

    const [formData, setFormData] = useState({
        user_username: "",
        location_id: "",
        person_id: "",
        profile_id: "",
        person_firstname: "",
        person_secondname: "",
        person_surname: "",
        person_secondsurname: "",
        person_email: "",
        person_phone: "",
        person_birthdate: ""
    });

    const [formDataPassword, setFormDataPassword] = useState({
        user_password: "",
        repeat_user_password: ""
    });

    const [locations, setLocations] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
    const [passwordError, setPasswordError] = useState(false); // Estado para manejar el error de contraseña
    const [passwordErrorRepeat, setPasswordErrorRepeat] = useState(false);

    // Permisos
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const { app } = useContext(AppContext);
    
    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        if (birthdate?.hasOwnProperty('_d')) {
            setFormData({ ...formData, person_birthdate: birthdate?.format('YYYY-MM-DD') });
        }
    }, [birthdate]);

    useEffect(() => {
        setShowLocation(false);

        if (formData.profile_id !== "" && formData.profile_id === 3) {
            setShowLocation(true);
        }
    }, [formData.profile_id]);

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        if (d.person_birthdate !== null) {
            setBirthdate(moment(d.person_birthdate));
        }
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError();
            })
    }

    useEffect(() => {
        
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
            .then(response => {
                if (response.success) {
                    setProfiles(handlerApp.getResultFromObject(response.data, 'profiles'));
                    setLocations(handlerApp.getResultFromObject(response.data, 'locations'));
                    if (id !== undefined) {
                        setTitle(t('personComponent.updateUser'))
                        sendRequest();
                    } else {
                        setTitle(t('personComponent.createUser'))
                        handlerApp.setLoader(false);
                    }
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError(error);
            })
    }, [])

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        if (key === "user_password" && e.target.value.length < 4) { // Validar la longitud mínima de la contraseña
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }

        setFormData({
            ...formData,
            [key]: e.target.value
        });
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.user_password.length < 4) { // Validar la longitud mínima de la contraseña
            setPasswordError(true);
            return;
        }
        handlerApp.setLoader(true);
        const pass = btoa(unescape(encodeURIComponent(btoa(unescape(encodeURIComponent(formData.user_password))))));
        let body = {
            ...formData,
            user_password: pass
        }
        api.post(endpoint, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleUpdatePassword = (e) => {
        e.preventDefault();
        if (formDataPassword.user_password.length < 4) { // Validar la longitud mínima de la contraseña
            setPasswordError(true);
            return;
        }else{
            setPasswordError(false);
        }
        if (formDataPassword.user_password !== formDataPassword.repeat_user_password) { // Validar la longitud mínima de la contraseña
            setPasswordErrorRepeat(true);
            return;
        }else{
            setPasswordErrorRepeat(false);
        }
        // handlerApp.setLoader(true);
        const pass = btoa(unescape(encodeURIComponent(btoa(unescape(encodeURIComponent(formDataPassword.user_password))))));

        let body = {
            user_password: pass
        }
        api.put(endpoint + "/" + id, body,{
            action: "updatePassword"
        })
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        handlerApp.setLoader(true);

        api.put(endpoint + "/" + id, formData)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    return (
        <div className="app container">
            <div className="title-section-forms">
                {title}
            </div>
            <div className="section-forms">
                <form>
                    <div className="row">
                        { (id !== undefined) && (
                            <div className="col-md-12">
                                <h4>{t('personComponent.userInformation')}</h4>
                            </div>
                        )}
                        <div className="col-md-4 mb-2">
                            <TextField 
                                name="user_username" 
                                autoComplete="new-username"
                                onChange={handleForm} 
                                value={formData.user_username}
                                label={t('user')} 
                                required 
                                variant="standard" 
                                size="small"
                                // disabled={readonly} 
                            />
                        </div>
                        <div className="col-md-4 mb-2">
                            <BoldSelect 
                                title={t('profile')} 
                                value={formData.profile_id} 
                                name="profile_id" options={profiles} 
                                onChange={handleForm} 
                                variant="standard" 
                            />
                        </div>
                        {showLocation && (
                            <div className="col-md-4 mb-2">
                                <BoldSelect 
                                    title={t('locationComponent.location')} 
                                    value={formData.location_id} 
                                    variant="standard" 
                                    name="location_id" 
                                    options={locations} 
                                    onChange={handleForm} 
                                />
                            </div>
                        )} 
                        <div className="col-md-4 mb-2">
                            <TextField 
                                name="person_firstname" 
                                onChange={handleForm} 
                                value={formData.person_firstname} 
                                label={t('personComponent.firstName')} 
                                required 
                                variant="standard" 
                                size="small" 
                            />
                        </div>
                        <div className="col-md-4 mb-2">
                            <TextField 
                                name="person_secondname" 
                                onChange={handleForm} 
                                value={formData.person_secondname} 
                                label={t('personComponent.secondName')} 
                                variant="standard" 
                                size="small" 
                            />
                        </div>
                        <div className="col-md-4 mb-2">
                            <TextField 
                                name="person_surname"
                                onChange={handleForm} 
                                value={formData.person_surname} 
                                label={t('personComponent.surName')} 
                                required 
                                variant="standard" 
                                size="small" 
                            />
                        </div>
                        <div className="col-md-4 mb-2">
                            <TextField 
                                name="person_secondsurname" 
                                onChange={handleForm} 
                                value={formData.person_secondsurname} 
                                label={t('personComponent.secondSurname')} 
                                variant="standard" 
                                size="small" 
                            />
                        </div>
                        <div className="col-md-4 mb-2">
                            <TextField 
                                name="person_email" 
                                onChange={handleForm} 
                                value={formData.person_email} 
                                label={t('email')} 
                                required 
                                variant="standard" 
                                size="small" 
                            />
                        </div>
                        { (id === undefined) && (
                            <div className="col-md-4 mb-2">
                                <TextField
                                    required
                                    name="user_password"
                                    label={t('password')}
                                    type={showPassword ? 'text' : 'password'}
                                    variant="standard"
                                    size="small"
                                    autoComplete="new-password"
                                    value={formData.user_password}
                                    onChange={(e) => handleForm(e, 'user_password')}
                                    error={passwordError} // Aplicar el estado de error al campo de contraseña
                                    helperText={passwordError && t("errors.passwordLength")} // Mostrar mensaje de error si la contraseña no cumple con la longitud mínima
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div> 
                        )}
                        
                        <div className="col-md-4 mb-2">
                            <InputMask
                                mask="+9 (999) 999 9999"
                                maskChar=" "
                                name="person_phone"
                                onChange={handleForm}
                                value={formData.person_phone}
                                id="person_phone"
                            // required variant="standard" size="small"
                            >
                                {() => <TextField
                                    name="person_phone"
                                    onChange={handleForm}
                                    value={formData.person_phone}
                                    required variant="standard"
                                    size="small"
                                    type="text"
                                    label={t('phone') + '*'}
                                />}
                            </InputMask>
                        </div>
                        <div className="col-md-4 mb-2">
                            <DatePicker
                                label={t('personComponent.birthdate')}
                                format="YYYY/MM/DD"
                                name="person_birthdate"
                                variant="standard"
                                onChange={(val) => { setBirthdate(val) }}
                                value={birthdate}
                                slotProps={{ textField: { size: 'small', variant: "standard" } }}
                            />
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                {id === undefined && <button className="bold-btn bold-btn-dark" onClick={handleSubmit}>{t('submit')}</button>}
                                {id && <button className="bold-btn bold-btn-dark" onClick={handleUpdate}>{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
                <form>
                    <div className="row">
                        { (id !== undefined) && (
                            <div className="col-md-12">
                                <h4>{t('personComponent.managePassword')}</h4>
                            </div>
                        )}
                        { (id !== undefined) && (
                            <>
                                <div className="col-md-6 mb-2">
                                    <TextField
                                        required
                                        name="user_password"
                                        label={t('password')}
                                        type={showPassword ? 'text' : 'password'}
                                        variant="standard"
                                        size="small"
                                        value={formDataPassword.user_password}
                                        onChange={(e) => setFormDataPassword({ 
                                            ...formDataPassword, 
                                            [e.target.name]: e.target.value 
                                        })}
                                        error={passwordError} // Aplicar el estado de error al campo de contraseña
                                        helperText={passwordError && t("errors.passwordLength")} // Mostrar mensaje de error si la contraseña no cumple con la longitud mínima
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <TextField
                                        required
                                        name="repeat_user_password"
                                        label={t('repeatPassword')}
                                        type={showPasswordRepeat ? 'text' : 'password'}
                                        variant="standard"
                                        size="small"
                                        value={formDataPassword.repeat_user_password}
                                        onChange={(e) => setFormDataPassword({ 
                                            ...formDataPassword, 
                                            [e.target.name]: e.target.value 
                                        })} // Actualizar el estado del campo de contraseña con el valor del campo de entrada (e.target.name, e.target.value)}
                                        error={passwordErrorRepeat} // Aplicar el estado de error al campo de contraseña
                                        helperText={passwordErrorRepeat && t("errors.notEqualsPasswords")} // Mostrar mensaje de error si la contraseña no cumple con la longitud mínima
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                                                    >
                                                    {showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                {id && <button className="bold-btn bold-btn-dark" onClick={handleUpdatePassword}>{t('update')}</button>}
                            </div>
                        </div>
                        <div className="row d-flex mt-4 justify-content-end">
                            <div className="col-md-2">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UsersForms;
