import { useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';

const columns = [
    {
        id: 'name',
        name: 'Name',
        selector: row => row.name,
        sortable: true,
    },
    {
        id: 'calories',
        name: 'Calories',
        selector: row => row.calories,
        sortable: true
    },
    {
        id: 'fat',
        name: 'Fat',
        selector: row => row.fat,
        sortable: true
    },
    {
        id: 'carbs',
        name: 'Carbs',
        selector: row => row.carbs,
        sortable: true
    },
    {
        id: 'protein',
        name: 'Protein',
        selector: row => row.protein,
        sortable: true
    },
];

const data = [
    { name: 'Cupcake', calories: 305, fat: 3.7, carbs: 67, protein: 4.3 },
    { name: 'Donut', calories: 452, fat: 25.0, carbs: 51, protein: 4.9 },
    { name: 'Eclair', calories: 262, fat: 16.0, carbs: 24, protein: 6.0 },
    { name: 'Frozen yoghurt', calories: 159, fat: 6.0, carbs: 24, protein: 4.0 },
    { name: 'Gingerbread', calories: 356, fat: 16.0, carbs: 49, protein: 3.9 },
    { name: 'Honeycomb', calories: 408, fat: 3.2, carbs: 87, protein: 6.5 },
    { name: 'Ice cream sandwich', calories: 237, fat: 9.0, carbs: 37, protein: 4.3 },
    { name: 'Jelly Bean', calories: 375, fat: 0.0, carbs: 94, protein: 0.0 },
    { name: 'KitKat', calories: 518, fat: 26.0, carbs: 65, protein: 7.0 },
    { name: 'Lollipop', calories: 392, fat: 0.2, carbs: 98, protein: 0.0 },
    { name: 'Nougat', calories: 360, fat: 19.0, carbs: 9, protein: 37.0 },
    { name: 'Oreo', calories: 437, fat: 18.0, carbs: 63, protein: 4.0 }
];


export default function TablesView() {

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
            || item.protein.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.carbs.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.calories.toString().toLowerCase().includes(filterText.toLowerCase())
    );

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText}></input>
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <>
            <div className="app">
                <div className="container bold-container-content">
                    <DataTable
                        title="Titulo de tabla"
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        selectableRows
                        persistTableHead
                    />
                </div>
            </div>
        </>
    );
}