import { useState } from "react";
import env from "../env-local";
import HandlerApp from "../utils/handlerApp";
import { useTranslation } from "react-i18next";

export const useForm = (data, endpoint, filters) => {
    const [filterText, setFilterText] = useState('');
    const [filterInitDate, setInitFilterDate] = useState('');
    const [filterEndDate, setEndFilterDate] = useState('');
    const [filterSelect, setFilterSelect] = useState('');
    const handlerApp = HandlerApp();
    const [t] = useTranslation("global");

    const download = (url = `/${endpoint}/download`, info = {}) => {
        if (!filteredItems) {
            handlerApp.showError('No hay datos para descargar');
            return false;
        }
        const body = filteredItems.length
            ? filteredItems
            : info;


        handlerApp.setLoader(true);
        fetch(
            env.urlBackend + url, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Authorization": localStorage.getItem('token')
            },
        })
            .then(handleResponse)
            .then(response => {
                handlerApp.setLoader(false);
                if (response instanceof Blob) {
                    // Manejar el blob (archivo)
                    const url = URL.createObjectURL(response);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = endpoint;  // Asigna un nombre de archivo adecuado aquí.
                    document.body.appendChild(a);
                    a.click();
                    URL.revokeObjectURL(url);
                } else {
                    handlerApp.handlerResponse(response);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }
    const matchesFilter = (value, filter) => {
        if (!filter) return true; // Si el filtro no está definido, siempre devuelve true
        if (!value) return false;
        return value.toString().toLowerCase().includes(filter.toString().toLowerCase());
    };

    const isWithinDateRange = (dateString, initDate, endDate) => {
        if (!initDate || !endDate) return true; // Si no se definen fechas, siempre devuelve true
        if (!dateString) return false;

        const dateValue = new Date(dateString);
        const initDateValue = new Date(initDate);
        const endDateValue = new Date(endDate);

        return dateValue >= initDateValue && dateValue <= endDateValue;
    };

    const filteredItems = data.filter(item => {

        const dates = filters.filter(({ type }) => type === 'isDate')[0];
        const selects = filters.filter(({ type }) => type === 'isSelect');

        const valueDate = dates ? item[dates.field] : '';

        // Si hay un filtro de texto, verificar coincidencia en cualquier campo
        const matchesTextFilter = filterText
            ? filters.some(({ field }) => matchesFilter(item[field], filterText))
            : true; // Si no hay filtro de texto, siempre devolver true

        // Si hay fechas de filtro, verificar que la fecha esté en el rango
        const matchesDateFilter = isWithinDateRange(valueDate, filterInitDate, filterEndDate);

        // Si hay un valor seleccionado en el select
        const matchesSelectFilter = selects.map(({ field }) => matchesFilter(item[field], filterSelect))[0];
        return matchesTextFilter && matchesDateFilter && matchesSelectFilter;
    });


    function handleResponse(response) {
        const contentType = response.headers.get("Content-Type");

        if (contentType && contentType.includes("application/json") || contentType.includes("text/html")) {
            return response.json();
        } else if (contentType && (contentType.includes("application/vnd.ms-excel"))) {  // Añade más tipos de MIME si es necesario.
            return response.blob();
        } else {
            throw new Error("Tipo de respuesta no soportado");
        }
    }

    return {
        filterText,
        filteredItems,
        filterInitDate,
        filterEndDate,
        filterSelect,
        setFilterText,
        setInitFilterDate,
        setEndFilterDate,
        setFilterSelect,
        download
    }
}