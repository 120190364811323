import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import BoldSelect from "../../../utils/BoldSelect";
import Swal from "sweetalert2";

const MachineLocations = () => {
    const endpoint = "machinelocations";
    const parent = "Machine";
    const [t] = useTranslation("global");
    const title = t('machines.title');
    const sub_title = t('machines.sub_title');
    const prefix = "machine";
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    const { app } = useContext(AppContext);
    const [filters, setFilters] = useState({
        serial: [],
        locations: []
    });
    const [initialLoad, setInitialLoad] = useState(true);
    const [firstCallSetTimeOut, setFirstCallSetTimeOut] = useState(false);
    const [filterDate, setFilterDate] = useState();
    const [updateInterval, setUpdateInterval] = useState(false);
    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        setFilterDate("day");
    }, [])

    useEffect(() => {
        if (filterDate !== undefined) {
            sendRequest();
        }
        if(filterDate !== "day"){
            setUpdateInterval(!updateInterval)
        }
        if(filterDate === "day" && firstCallSetTimeOut === true && initialLoad === false) {
            setUpdateInterval(!updateInterval)
        }
    },[filterDate]);

    useEffect(()=>{
        if(initialLoad === false) {
            setFirstCallSetTimeOut(true);
        }
    },[initialLoad])

    useEffect(() => {
        let intervalId = null;
        if (initialLoad === false) {
            intervalId = setInterval(fetchData, 10000);
        }
        return () => clearInterval(intervalId);
    }, [firstCallSetTimeOut, updateInterval])

    const fetchData = async () => {
        try {
            if(firstCallSetTimeOut === true && filterDate === "day") {
                let params = (filterDate === undefined) ? {} : { action: filterDate }
                const response = await api.get(endpoint,params);
                if (response.success) {
                    // Obtén las opciones únicas de machine_serial y machine_label por separado
                    const serialOptions = response.data.map(item => item.machine_serial);
                    const labelOptions = response.data.map(item => item.machine_label || 'N/A');

                    // Combina machine_serial y machine_label en un solo arreglo de opciones
                    const combinedSerialOptions = serialOptions.map((serial, index) => `${serial} - ${labelOptions[index]}`);

                    // Actualiza el estado de los filtros con las opciones combinadas
                    setFilters({
                        ...filters,
                        serial: combinedSerialOptions,
                        location: handlerApp.getFiltersFromData(response.data, 'location_name')
                    });
                    // Reformatea combinedSerialOptions para que sea un arreglo de objetos
                    const formattedSerialOptions = combinedSerialOptions.map(option => ({
                        code: option,
                        name: option,
                    }));

                    // Actualiza el estado de los filtros con las opciones formateadas
                    setFilters({
                        ...filters,
                        serial: formattedSerialOptions,
                        location: handlerApp.getFiltersFromData(response.data, 'location_name'),
                    });
                    setData(response.data);
                } else {
                    setData([]);
                    handlerApp.handlerResponse(response);
                } 
            }
        } catch (error) {
            setData([]);
            handlerApp.showError(t(error.message));
        } finally {
            setInitialLoad(false);
        }
    };

    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                deleteRow(id, action);
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                sendRequest();
                break;
            case 'out':
                sendCashout(id);
                break;
            default:
                break;
        }
    }

    const checkSuspiciousWithdrawal = (id) =>{
        handlerApp.setLoader(true);
        api.post('/check-suspicious-withdrawal/' +id)
        .then( response => {
            if(response.success){
                let data = response.data[0];
                if(data.trusted === true){
                    collectMachine(id);
                }else{
                    Swal.fire({
                            title: `${t('confirmAction.confirmSuspiciousActivity')}`,
                            text: `${t('confirmAction.suspiciousActivityDetail')}`,
                            icon: 'error',
                            showDenyButton: false,
                            showCancelButton: true,
                            confirmButtonText: `${t('confirmAction.confirm')}`,
                            confirmButtonColor: "green",
                            cancelButtonText: `${t('cancel')}`
                    }).then((result) => {
                        if (result.isConfirmed) {
                            collectMachine(id);
                        }else{
                            Swal.fire({
                                text: `${t('cashOut.withdrawalNotProcessed')}`
                                // text: `${t('cashOut.suspiciousActivityDetail')}`
                            })
                            handlerApp.setLoader(false);
                        }
                    })
                }
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const collectMachine = (id)=>{
        handlerApp.setLoader(true);
        api.get('collect/' + id)
        .then(response => {
            if (response.success) {
                if (response.data[0].cashout_out > 0) {
                    let data = {
                        message: "Dinero retirado: " + handlerApp.formatCurrency(response.data[0].cashout_out)
                    }
                    handlerApp.setLoader(false);
                    handlerApp.showOk(data);
                } else {
                    handlerApp.setLoader(false);
                    handlerApp.showError("No hay dinero para retirar");
                }
            } else {
                handlerApp.setLoader(false);
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const sendCashout = (id) => {
        Swal.fire({
            title: `${t('confirmAction.sendCashout')}`,
            text: `${t('confirmAction.confirmSendCashout')}`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `${t('confirmAction.confirm')}`,
            confirmButtonColor: "green",
            cancelButtonText: `${t('cancel')}`
        }).then((result) => {
            if (result.isConfirmed) {
                checkSuspiciousWithdrawal(id);
                // api.get('collect/' + id)
                // .then(response => {
                //     if (response.success) {

                //         if (response.data[0].cashout_out > 0) {

                //             let data = {
                //                 message: "Dinero retirado: " + handlerApp.formatCurrency(response.data[0].cashout_out)
                //             }
                //             handlerApp.setLoader(false);
                //             handlerApp.showOk(data);
                //         } else {
                //             handlerApp.setLoader(false);
                //             handlerApp.showError("No hay dinero para retirar");
                //         }

                //     } else {
                //         handlerApp.setLoader(false);
                //         handlerApp.handlerResponse(response);
                //     }
                // })
                // .catch(error => {
                //     handlerApp.setLoader(false);
                //     handlerApp.showError(t(error.message));
                // })
            }
        });
    };

    const optionRequest = (id, action) => {
        const confirmButtonColor = (action === 'active') ? "green" : "red";
        Swal.fire({
            title: `${action === 'active' ? t('activateAlert.activateRecord') : t('activateAlert.deactivateRecord')}`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `${t('activateAlert.confirm')}`,
            confirmButtonColor: confirmButtonColor,
            cancelButtonText: `${t('cancel')}`
        }).then((result) => {
            if (result.isConfirmed) {
                const status = (action === 'active') ? 1 : 0;
                const body = { action: 'updateStatus', status };
                handlerApp.setLoader(true);
                api.put(endpoint + "/" + id, body)
                .then(response => {
                    if (response.success) {
                        handlerApp.showOk(response);
                        sendRequest();
                    } else {
                        handlerApp.handlerResponse(response);
                    }
                })
                .catch(error => {
                    handlerApp.showError(t(error.message));
                })
                .finally(() => {
                    handlerApp.setLoader(false);
                });
            }
        });
    };

    const deleteRow = (id) => {
        Swal.fire({
            title: `${t('deleteAlert.deleteRecord')}`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `${t('deleteAlert.delete')}`,
            confirmButtonColor: "red",
            cancelButtonText: `${t('cancel')}`
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                api.deleteMethod(endpoint + '/' + id)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            sendRequest();
                        } else {
                            handlerApp.handlerResponse(response);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                    })
                    .finally(() => {
                        handlerApp.setLoader(false);
                    });
            }
        });
    };

    const columns = [
        {
            id: 'machine_id',
            name: 'id',
            selector: row => row.machine_id,
            sortable: true,
            width: "70px"
        },
        {
            id: 'machine_serial',
            name: t('machines.machine'),
            selector: row => row.machine_serial + " - " + (row.machine_label || 'N/A'),
            sortable: true
        },
        // {
        //     id: 'machine_label',
        //     name: t('machines.label'),
        //     selector: row => row.machine_label || 'N/A',
        //     sortable: true
        // },
        {
            id: 'location_name',
            name: t('machines.location'),
            selector: row => row.location_name,
            sortable: true
        },
        {
            id: 'cash_in_total',
            name: t('machines.cashin'),
            selector: row => handlerApp.formatCurrency(row.cash_in_total),
            sortable: true,
            width: "150px"
        },
        {
            id: 'cash_out_total',
            name: t('machines.cashout'),
            selector: row => handlerApp.formatCurrency(row.cash_out_total),
            sortable: true,
            width: "150px"
        },
        {
            id: 'cash_diff',
            name: t('machines.Profit'),
            selector: row => handlerApp.machineDiff(row.cash_in_total - row.cash_out_total),
            sortable: true,
            width: "110px"
        },
        {
            id: 'machine_status',
            name: t('machines.status'),
            // selector: row => handlerApp.machineStatus('offline'),
            selector: row => handlerApp.machineStatus(row.machine_status),
            sortable: true,
            width: "110px"
        },
        {
            name: t("action"),
            cell: (row) => { return <div style={{ justifyContent: "end", width: "100%", display: "flex" }}>{handlerApp.actions(row, permissions, prefix, handlerAction, row.machine_status)}</div> },
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint,{
            action: filterDate
        })
            .then(response => {
                if (response.success) {
                    // Obtén las opciones únicas de machine_serial y machine_label por separado
                    const serialOptions = response.data.map(item => item.machine_serial);
                    const labelOptions = response.data.map(item => item.machine_label || 'N/A');

                    // Combina machine_serial y machine_label en un solo arreglo de opciones
                    const combinedSerialOptions = serialOptions.map((serial, index) => `${serial} - ${labelOptions[index]}`);

                    // Actualiza el estado de los filtros con las opciones combinadas
                    setFilters({
                        ...filters,
                        serial: combinedSerialOptions,
                        location: handlerApp.getFiltersFromData(response.data, 'location_name')
                    });
                    // Reformatea combinedSerialOptions para que sea un arreglo de objetos
                    const formattedSerialOptions = combinedSerialOptions.map(option => ({
                        code: option,
                        name: option,
                    }));

                    // Actualiza el estado de los filtros con las opciones formateadas
                    setFilters({
                        ...filters,
                        serial: formattedSerialOptions,
                        location: handlerApp.getFiltersFromData(response.data, 'location_name'),
                    });
                    setData(response.data);
                    handlerApp.setLoader(false);
                    setInitialLoad(false);
                } else {
                    setData([]);
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                setData([]);
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }



    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter((item) =>
        item.machine_id && item.machine_id.toString().toLowerCase().includes(filterText.toLowerCase()) ||
        (item.machine_serial + " - " + (item.machine_label || 'N/A')).toLowerCase().includes(filterText.toLowerCase()) ||
        item.location_name?.toLowerCase().includes(filterText.toLowerCase()) ||
        item.cash_in_total?.toString().toLowerCase().includes(filterText.toLowerCase()) ||
        item.cash_out_total?.toString().toLowerCase().includes(filterText.toLowerCase()) ||
        item.cash_diff?.toString().toLowerCase().includes(filterText.toLowerCase()) ||
        item.machine_status?.toString().toLowerCase().includes(filterText.toLowerCase())
    );

    const calculateTotalProfit = () => {
        const total = filteredItems.reduce((acc, item) => acc + parseFloat(item.cash_in_total - item.cash_out_total) || 0, 0);
        const formattedTotal = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total);
        return formattedTotal;
    };

    return (
        <div className="app container">
            {/* <!-- titulo --> */}
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">{sub_title}</p>
                </section>
            </div>
            {/* <!-- Menu interno --> */}
            <nav className="child-menu">
                {menus?.map(item => {
                    return <NavLink key={item.menu_children} to={"/" + item.menu_children_action} activeclassname="active">{item.menu_children}</NavLink>
                })}
            </nav>
            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4 className="col-md-4">{t("machines.filters")}</h4>
                <div className="row">
                    <div className="col-md-4">
                        <BoldSelect title={t("machines.location")} name="location" onChange={e => setFilterText(e.target.value)} value="" options={filters.location}></BoldSelect>
                    </div>
                    <div className="col-md-4">
                        <BoldSelect title={t("machines.machine")} name="machine" onChange={e => setFilterText(e.target.value)} value="" options={filters.serial}></BoldSelect>
                    </div>
                    <div className="col-md-3">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>
                    <div className="col-md-1 d-flex justify-content-end">
                        {permissions?.indexOf("create") !== -1 && (
                            <button className="btn-table-actions" onClick={() => { handlerAction(null, 'create') }}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                        )}
                        <button className="btn-table-actions" onClick={() => { handlerAction(null, 'refresh') }}>
                            <span className="material-symbols-outlined">autorenew</span>
                        </button>
                    </div>
                </div>
                <div className="d-flex flex-direction-row">
                    <button className={(filterDate === 'hour') ? "btn-filter-date btn-active" : "btn-filter-date" } onClick={()=>setFilterDate('hour')}>1 hora</button>
                    <button className={(filterDate === 'day') ? "btn-filter-date btn-active" : "btn-filter-date" } onClick={()=>setFilterDate('day')}>1 dia</button>
                    <button className={(filterDate === 'week') ? "btn-filter-date btn-active" : "btn-filter-date" } onClick={()=>setFilterDate('week')}>1 semana</button>
                    <button className={(filterDate === 'month') ? "btn-filter-date btn-active" : "btn-filter-date" } onClick={()=>setFilterDate('month')}>1 mes</button>
                </div>
            </section>
            {/* <!-- Tabla --> */}
            <div className="dataTable">
                <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle}
                    selectableRows
                    persistTableHead
                    subHeader
                    subHeaderComponent={<h4 className="col-md-12 text-sm-end">{t("machines.total")} {calculateTotalProfit()}</h4>}
                />
            </div>
        </div>
    )
}

export default MachineLocations;