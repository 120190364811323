import { Alert, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import env from "../../env-local";
import "../theme/Barberlytics/css/login.css";
import logo from "../../assets/img/logo.png";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

// import i18next from "i18next";
// import global_es from "../../translate/es/global.json";
// import global_en from "../../translate/en/global.json";

const SignUp = () => {
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState();
    const [signup, setSignup] = useState({
        name: "",
        lastname: "",
        company_name: "",
        email: "",
        username: "",
        howManyBarbers: ""
    });

    const [t,i18n] = useTranslation("global");
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [language,setLanguage] = useState("");

    useEffect(()=>{
        let lang = localStorage.getItem("language");
        if(lang == "" || lang == null){
            setLanguage("es")
        }else{
            setLanguage(lang);
        }
    },[])

    useEffect(()=>{
        i18n.changeLanguage(language)
        localStorage.setItem('language',language);
    },[language])


    const handleLogin = (e) => {
        let key = e.target.name;
        setSignup({
            ...signup,
            [key]: e.target.value
        });
        setError();
    }

    const nav = useNavigate();

    const handleFormSignUp = (data) => {
        let body = {
            ...data
        }
        setLoader(true);
        axios.post(env.urlBackend + '/signup', body)
        .then(response => {
            if(response.data.success){
                localStorage.setItem('phone',data.username);
                nav('/authenticate/verifyotp');
            }else{
                setError(t(error.response.data.message));
                setLoader(false);
            }
        }).catch(error => {
            setLoader(false);
            setError(t(error.response.data.message));
        })
    }

    return (
        <>
            <div className="language-login">
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="material-symbols-outlined">language</span>
                    </button>
                    <ul className="dropdown-menu">
                        <li><button className={language === "es" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("es")}}>ES</button></li>
                        <li><button className={language === "en" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("en")}}>EN</button></li>
                    </ul>
                </div>
            </div>
            <div className="d-flex justify-content-center bg-login">
                <div className="login-container">
                    <div className="section-login login">
                        <img src={logo} alt="logo-brava" className="logo-login"/>
                        <h1 className="title-login">{t("loginComponent.startNow")}</h1>
                        <p className="light">{t("loginComponent.enterInformation")}</p>
                        {/* <form action="/" onSubmit={handleFormLogin}> */}
                        <form action="/" onSubmit={handleSubmit(handleFormSignUp)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <TextField label={t('name')} variant="outlined" size="small" {...register("name",{ required: true, maxLength: 40 })}
                                     error={errors.name != null}
                                     helperText={
                                        errors.name?.type === 'required' ? t("errors.required") : 
                                        errors.name?.type === 'maxLength' ? t("errors.maxLength") : ""}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <TextField label={t('lastname')} variant="outlined" size="small" {...register("lastname",{ required: true, maxLength: 60 })}
                                     error={errors.lastname != null}
                                     helperText={
                                        errors.lastname?.type === 'required' ? t("errors.required") : 
                                        errors.lastname?.type === 'maxLength' ? t("errors.maxLength") : ""}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <TextField label={t('phone')} variant="outlined" size="small" {...register("username",{ required: true, maxLength: 15 , minLength: 10})}
                                     error={errors.username != null}
                                     helperText={
                                        errors.username?.type === 'required' ? t("errors.required"): 
                                        errors.username?.type === 'maxLength' ? t("errors.maxLength") : 
                                        errors.username?.type === 'minLength' ? t("errors.minLength") : ""}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <TextField label={t('email')} type="email" variant="outlined" size="small" {...register("email",{ 
                                            required: true, 
                                            maxLength: 80, 
                                            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,8}$/ 
                                        })}
                                        error={errors.email != null}
                                        helperText={
                                            errors.email?.type === 'required' ? t("errors.required"): 
                                            errors.email?.type === 'pattern' ? t("errors.pattern") : 
                                            errors.email?.type === 'maxLength' ? t("errors.maxLength") : ""}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <TextField label={t('companyComponent.company')} variant="outlined" size="small" {...register("company_name",{ 
                                            required: true
                                        })}
                                        error={errors.company_name != null}
                                        helperText={
                                            errors.company_name?.type === 'required' ? t("errors.required"): ""
                                        }
                                    />
                                </div>
                                <div className="col-md-12">
                                    <TextField label={t('companyComponent.howManyBarbers')} type="number" variant="outlined" size="small" {...register("howManyBarbers",{ 
                                            required: true, 
                                            min: 0
                                        })}
                                        error={errors.howManyBarbers != null}
                                        helperText={
                                            errors.howManyBarbers?.type === 'required' ? t("errors.required"): 
                                            errors.howManyBarbers?.type === 'min' ? t("errors.min") : ""
                                        }
                                    />
                                </div>
                            </div>
                            {error &&
                                <Alert className="mb-3" severity="error">{error}</Alert>
                            }
                            <center>
                                <button className="btn btn-primary w-100 mb-2" type="submit">{t('send')}</button>
                                <Link to={"/authenticate"} className="btn">{t('back')}</Link><br/><br/>
                            </center>
                        </form>
                    </div>
                </div>
            </div>
            {
                loader &&
                <>
                    <div className="content-loader">
                        <CircularProgress />
                    </div>
                </>
            }
        </>
    )
}

export default SignUp;