
import App from "../components/App";

import { createBrowserRouter } from "react-router-dom";
import Home from "../components/pages/Home";
import Error404 from "../components/pages/Error404";
import Login from "../components/pages/Login";
import TablesView from "../components/pages/TablesView";

import VerifyOTP from "../components/pages/VerifyOTP";

import SignUp from "../components/pages/SignUp";
import Container from "../components/theme/Barberlytics/pages/Container";
import ForgotNumber from "../components/pages/ForgotNumber";
import Contact from "../components/pages/Contact";

import Cashout from "../components/pages/Cashout/Cashout";
import CashoutForms from "../components/pages/Cashout/CashoutForms";
import CashoutView from "../components/pages/Cashout/CashoutView";

import CashIn from "../components/pages/Cash/CashIn";
import CashInForms from "../components/pages/Cash/CashInForms";
import CashInView from "../components/pages/Cash/CashInView";

import MachineLocations from "../components/pages/MachineLocations/MachineLocations";
import MachineLocationsForms from "../components/pages/MachineLocations/MachineLocationsForm";
import MachineLocationsView from "../components/pages/MachineLocations/MachineLocationsView";

import Locations from "../components/pages/Locations/Locations";
import LocationsView from "../components/pages/Locations/LocationsView";
import LocationsForms from "../components/pages/Locations/LocationsForm";

import ActionLog from "../components/pages/Logs/ActionLog";
import ActionLogForms from "../components/pages/Logs/ActionLogForms";
import ActionLogView from "../components/pages/Logs/ActionLogView";
import ReportsGeneral from "../components/pages/Reports/ReportsGeneral";
import ReportsMachine from "../components/pages/Reports/ReportsMachine";

import AccessLog from "../components/pages/Logs/AccessLog";
import AccessLogView from "../components/pages/Logs/AccessLogView";

import Users from "../components/pages/Users/Users";
import UsersView from "../components/pages/Users/UsersView";
import UsersForms from "../components/pages/Users/UsersForm";
import Profile from "../components/pages/profile/Profile";

const router = createBrowserRouter([
    {
        path: "/authenticate",
        element: <Container />,
        errorElement: <Error404 />,
        children: [
            {
                path: "",
                element: <Login />
            },
            {
                path: "verifyotp",
                element: <VerifyOTP />
            },
            {
                path: "signup",
                element: <SignUp />
            },
            {
                path: "forgot-number",
                element: <ForgotNumber />
            },
            {
                path: "contact",
                element: <Contact />
            },
        ]
    },
    {
        path: "/",
        element: <App />,
        errorElement: <Error404 />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/tables",
                element: <TablesView />
            },
            //CASH OUT
            {
                path: "cashout",
                element: <Cashout />
            },
            {
                path: "cashout/view/:id",
                element: <CashoutView />
            },
            {
                path: "cashout/create",
                element: <CashoutForms />
            },
            {
                path: "cashout/edit/:id",
                element: <CashoutForms />
            },
            //CASH IN
            {
                path: "cashin",
                element: <CashIn />
            },
            {
                path: "cashin/view/:id",
                element: <CashInView />
            },
            {
                path: "cashin/create",
                element: <CashInForms />
            },
            {
                path: "cashin/edit/:id",
                element: <CashInForms />
            },
            //MACHINE LOCATIONS
            {
                path: "machinelocations",
                element: <MachineLocations />
            },
            {
                path: "machinelocations/view/:id",
                element: <MachineLocationsView />
            },
            {
                path: "machinelocations/create",
                element: <MachineLocationsForms />
            },
            {
                path: "machinelocations/edit/:id",
                element: <MachineLocationsForms />
            },
            //LOCATIONS
            {
                path: "administration/locations",
                element: <Locations />
            },
            {
                path: "administration/locations/view/:id",
                element: <LocationsView />
            },
            {
                path: "administration/locations/create",
                element: <LocationsForms />
            },
            {
                path: "administration/locations/edit/:id",
                element: <LocationsForms />
            },
            //ACTION LOG
            {
                path: "actionlog",
                element: <ActionLog />
            },
            {
                path: "actionlog/view/:id",
                element: <ActionLogView />
            },
            {
                path: "actionlog/create",
                element: <ActionLogForms />
            },
            {
                path: "actionlog/edit/:id",
                element: <ActionLogForms />
            },
            //ACCES LOG
            {
                path: "accesslog",
                element: <AccessLog />
            },
            {
                path: "accesslog/view/:id",
                element: <AccessLogView />
            },
            // REPORTS
            {
                path: "reportGeneral",
                element: <ReportsGeneral />
            },
            {
                path: "reportMachine",
                element: <ReportsMachine />
            },
            //Users
            {
                path: "administration/users",
                element: <Users/>
            },
            {
                path: "administration/users/view/:id",
                element: <UsersView/>
            },
            {
                path: "administration/users/create",
                element: <UsersForms />
            },
            {
                path: "administration/users/edit/:id",
                element: <UsersForms />
            },
            {
                path: "configuration/profile/:id",
                element: <Profile/>
            }
        ]
    },
])

export default router;
