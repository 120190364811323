import { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../hooks/useForm";
import BoldSelect from "../../../utils/BoldSelect";

const ReportsMachine = () => {
    const endpoint = "/administration/locations";
    const parent = "Reports";
    const [t] = useTranslation("global");
    const title = t('reports.machine report');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    const { app } = useContext(AppContext);
    const [errors, setErros] = useState({
        cashin_date_init: false,
        cashin_date_end: false,
        machine: false,
    });
    const [machines, setMachines] = useState([]);
    const [machine, setMachine] = useState('');

    const {
        filterInitDate,
        filterEndDate,
        filterSelect,
        setFilterText,
        setInitFilterDate,
        setEndFilterDate,
        setFilterSelect,
        download
    } = useForm(
        data,
        endpoint,
        []
    );

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        sendRequest();
    }, [])

    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                deleteRow(id, action);
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                sendRequest();
                break;
            case 'download':
                const newErrors = {
                    ...errors,
                    cashin_date_init: !filterInitDate,
                    cashin_date_end: !filterEndDate,
                    machine: !machine,
                };

                setErros(newErrors);
                if (!newErrors.cashin_date_init && !newErrors.cashin_date_end && !newErrors.machine) {
                    
                    const actualMachine = (machine === 'All') ? '' : machine;
                    
                    download(
                        '/reports/machinesByDayOfWeek',
                        { filterInitDate, filterEndDate, machine: actualMachine }
                    );
                }
                break;
            case 'clear':
                setFilterText('');
                setInitFilterDate('');
                setEndFilterDate('');
                setFilterSelect(0);
                break;
            default:
                break;
        }
    }

    const optionRequest = (id, action) => {
        let body = {
            action: 'updateStatus',
            status: (action === 'active') ? 1 : 0
        }
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    sendRequest();
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const deleteRow = (id) => {
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + id)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    sendRequest();
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }


    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdateMachines"
        })
            .then(response => {
                if (response.success) {
                    setMachines(handlerApp.getResultFromObject(response.data, 'machines'));
                    setData(response.data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            });
    }

    return (
        <div className="app container">
            {/* <!-- titulo --> */}
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">{t("reports.sub_title")}</p>
                </section>
            </div>
            {/* <!-- Menu interno --> */}
            <nav className="child-menu">
                {menus?.map(item => {
                    return <NavLink key={item.menu_children} to={"/" + item.menu_children_action} activeclassname="active">{item.menu_children}</NavLink>
                })}
            </nav>
            {/* <!-- Form --> */}
            <section className="filters">
                <h4>{t("reports.list machines")}</h4>
                <div className="row justify-content-md-center">
                <div className="col-md-2">
                    <label htmlFor="cashin_date_end" className="form-label">{t("locationComponent.machine")}</label>
                    <BoldSelect
                        title={t("reports.machines")}
                        name="machine"
                        onChange={e => {
                            setErros({
                                ...errors,
                                machine: !e.target.value, // Validar si el valor está vacío
                            });
                            setMachine(e.target.value)
                        }}
                        value={machine}
                        options={machines.length > 0 ? [{ code: 'All', name: t("reports.alls") }, ...machines] : []}
                    ></BoldSelect>
                    {errors.machine && <small className="text-danger">{t("errors.required")}</small>}
                </div>
                    <div className="col-md-3">
                        <label htmlFor="cashin_date_init" className="form-label">{t("reports.start date")}</label>
                        <input
                            className="form-control"
                            type="datetime-local"
                            name="cashin_date_init"
                            id="cashin_date_init"
                            title={t("reports.start date")}
                            onChange={e => {
                                setErros({
                                    ...errors,
                                    cashin_date_init: false
                                });
                                setInitFilterDate(e.target.value);

                            }}
                            value={filterInitDate}
                        />
                        {
                            errors.cashin_date_init
                                ?
                                <small className="text-danger">{t("errors.required")}</small>
                                :
                                ''
                        }
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="cashin_date_end" className="form-label">{t("reports.final date")}</label>
                        <input
                            className="form-control"
                            type="datetime-local"
                            name="cashin_date_end"
                            id="cashin_date_end"
                            title={t("reports.final date")}
                            onChange={e => {
                                setEndFilterDate(e.target.value);
                                setErros({
                                    ...errors,
                                    cashin_date_end: false
                                });
                            }}
                            min={filterInitDate}
                            value={filterEndDate}
                        />
                        {
                            errors.cashin_date_end
                                ?
                                <small className="text-danger">{t("errors.required")}</small>
                                :
                                ''
                        }
                    </div>
                    <div className="col-md-1 d-flex flex-column align-items-center">
                        <label htmlFor="cashin_date_end" className="form-label mb-0">{t("reports.download")}</label>
                        <button
                            className="btn-table-actions mt-1"
                            title="Descargar"
                            onClick={() => handlerAction(null, 'download')}
                        >
                            <span className="material-symbols-outlined fs-1">
                                download
                            </span>
                        </button>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ReportsMachine;