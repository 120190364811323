import { Alert, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import env from "../../env-local";
import "../theme/Barberlytics/css/login.css";
import logo from "../../assets/img/logo.png";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const Login = () => {
    const [loginForm, setLoginForm] = useState({
        fuser: ""
    });
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState();

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [passwordError, setPasswordError] = useState(false);

    const [t, i18n] = useTranslation("global");
    const [language, setLanguage] = useState("");
    useEffect(() => {
        let lang = localStorage.getItem("language");
        if (lang == "" || lang == null) {
            setLanguage("es")
        } else {
            setLanguage(lang);
        }
    }, [])

    useEffect(() => {
        i18n.changeLanguage(language)
        localStorage.setItem('language', language);
    }, [language])


    const handleLogin = (e) => {
        let key = e.target.name;
        setLoginForm({
            ...loginForm,
            [key]: e.target.value
        });
        setError();
    }

    const nav = useNavigate();


    const handleFormLogin = (data) => {
        setLoader(true);

        // Validar longitud mínima de la contraseña
        if (data.fpass.length < 4) {
            setPasswordError(true);
            setLoader(false);
            return; // Detener el proceso si la contraseña no cumple con la longitud mínima
        }

        let fpass = btoa(unescape(encodeURIComponent(btoa(unescape(encodeURIComponent(data.fpass))))));
        data.fpass = fpass;

        axios.post(env.urlBackend + '/login', data)
            .then(response => {
                if(response.data.success){
                    localStorage.setItem('user', response.data.user);
                    localStorage.setItem("username", response.data.username);
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("currentLocation", "");
                    localStorage.setItem("locations", response.data.locations);
                    localStorage.setItem("numberInformation", btoa(btoa(response.data.numberInformation)));
                    if (localStorage.getItem('user')) {
                        setLoader(false);
                        nav("/");
                    }
                    setLoader(false);
                }else{
                    console.log(response);
                    setLoader(false);
                }
            })
            .catch(error => {
                setLoader(false);
                console.log(error);
                setError(error.response.data.message);
            });
    }

    return (
        <>
            <div className="language-login">
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="material-symbols-outlined">language</span>
                    </button>
                    <ul className="dropdown-menu">
                        <li><button className={language === "es" ? 'dropdown-item selected' : 'dropdown-item'} onClick={() => { setLanguage("es") }}>ES</button></li>
                        <li><button className={language === "en" ? 'dropdown-item selected' : 'dropdown-item'} onClick={() => { setLanguage("en") }}>EN</button></li>
                    </ul>
                </div>
            </div>
            <div className="d-flex justify-content-center bg-login">
                <div className="login-container">
                    <div className="section-login login">
                        {/* <img src={logo} alt="logo-brava" className="logo-login"/> */}
                        <h1 className="title-login mx-auto">{t("loginComponent.titleLogin")}</h1>
                        <form action="/" onSubmit={handleSubmit(handleFormLogin)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <TextField label={t("user")} variant="outlined" size="small"
                                        {...register("fuser", { required: true })}
                                        error={errors.fuser != null}
                                        helperText={
                                            errors.fuser?.type === 'required' ? t("errors.required") : ""}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <TextField
                                        label={t("password")}
                                        variant="outlined"
                                        size="small"
                                        type="password"
                                        {...register("fpass", { required: true })}
                                        error={errors.fpass != null || passwordError} // Agregar passwordError a la validación
                                        helperText={
                                            errors.fpass?.type === 'required' ? t("errors.required") :
                                                passwordError ? t("errors.passwordLength") : "" // Mostrar mensaje de error si la contraseña no cumple con la longitud mínima
                                        }
                                    />
                                </div>
                            </div>
                            {/* <div className="d-flex justify-content-end mb-3">
                                <Link to={"/authenticate/forgot-number"}><button className='btn btn-forget'>{t("loginComponent.forgotNumber")}?</button></Link>
                            </div> */}
                            {error &&
                                <Alert className="mb-3" severity="error">{error}</Alert>
                            }
                            <center>
                                <button className="btn btn-primary w-100 mb-2" type="submit">Login</button>
                                {/* <span className="small-text regular">{t("loginComponent.dontHaveAccount")}?</span> */}
                                {/* <Link to={"/authenticate/signup"} className="btn btn-light w-100 mt-3">{t("loginComponent.startNow")}</Link><br/><br/> */}
                                <div className='footer-login'>
                                    <p className='small-text'>{t("loginComponent.byLoggingYourAccept")}</p>
                                    <Link to={"/terminos"} className="terms-link">{t("loginComponent.privacyPolicyAndTerms")}</Link>
                                </div>
                            </center>
                        </form>
                    </div>
                </div>
            </div>
            {
                loader &&
                <>
                    <div className="content-loader">
                        <CircularProgress />
                    </div>
                </>
            }
        </>
    )
}

export default Login;