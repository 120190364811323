import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import BoldSelect from "../../../utils/BoldSelect";
import { useForm } from "../../../hooks/useForm";

const AccessLog = () => {
    const endpoint = "accesslog";
    const parent = "Logs";
    const [t] = useTranslation("global");
    const title = t('accessLog.title');
    const sub_title = t('accessLog.sub_title');
    const prefix = "accesslog";
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    const { app } = useContext(AppContext);

    const [initFilterDate, setInitFilterDate] = useState('');
    const [endFilterDate, setEndFilterDate] = useState('');

    const [filters, setFilters] = useState({
        full_name: []
    });
    // const {

    // } = useForm(
    //     data, endpoint, [{
    //         'field': 'accesslog_id',
    //         'type': 'isSelect'
    //     }, {
    //         'field': 'accesslog_created_at',
    //         'type': 'isDate'
    //     }]
    // )

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        sendRequest();
    }, [])

    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'refresh':
                sendRequest();
                break;
            default:
                break;
        }
    }

    const columns = [
        {
            id: 'accesslog_id',
            name: 'id',
            selector: row => row.accesslog_id,
            sortable: true,
            width: "70px"
        },
        {
            id: 'full_name',
            name: t('name'),
            selector: row => row.full_name,
            sortable: true,
        },
        {
            id: 'accesslog_created_at',
            name: t('accessLog.date'),
            selector: row => handlerApp.getDate(row.accesslog_created_at),
            sortable: true
        },
        {
            name: t("action"),
            cell: (row) => { return handlerApp.actions(row, permissions, prefix, handlerAction) },
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
            .then(response => {
                if (response.success) {
                    setFilters({
                        ...filters,
                        full_name: handlerApp.getFiltersFromData(response.data, 'full_name'),
                        accesslog_created_at: handlerApp.getFiltersFromData(response.data, 'accesslog_created_at'),
                    });
                    setData(response.data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const [filteredItems, setFilteredItems] = useState(data);
    const [valueSelect, setValueSelect] = useState("");

    useEffect(() => {
        setFilteredItems(
            data?.filter(item =>
                item.accesslog_id
                // (
                //     item.accesslog_id.toString().toLowerCase().includes(filterText.toLowerCase()) 
                //     || item.full_name.toLowerCase().includes(filterText.toLowerCase())
                // )
                // || 
                // ( 
                //     item.accesslog_created_at && new Date(item.accesslog_created_at) >= new Date(initFilterDate) 
                //     && new Date(item.accesslog_created_at) <= new Date(endFilterDate)
                // )
            )
        );
    }, [data])

    useEffect(() => {
        
        setFilteredItems(
            data?.filter(item => {

                const matchesName =
                    item.accesslog_id &&
                    (
                        item.accesslog_id.toString().toLowerCase().includes(filterText.toLowerCase())
                        || item.full_name.toLowerCase().includes(filterText.toLowerCase())
                    );

                const matchesDate =
                    item.accesslog_created_at &&
                    new Date(item.accesslog_created_at) >= new Date(initFilterDate) &&
                    new Date(item.accesslog_created_at) <= new Date(endFilterDate);

                if (!filterText && initFilterDate && endFilterDate) {

                    return matchesDate;

                } else if (!initFilterDate || !endFilterDate) {

                    return matchesName;

                } else if (filterText && initFilterDate && endFilterDate) {

                    return matchesName && matchesDate;

                } else{

                    sendRequest();
                    
                }
            })
        );
    }, [initFilterDate, endFilterDate, filterText]);


    // useEffect(() => {
    //     setFilteredItems(
    //         data?.filter(item => 
    //             item.accesslog_id &&
    //             (
    //                 item.accesslog_id.toString().toLowerCase().includes(filterText.toLowerCase()) 
    //                 || item.full_name.toLowerCase().includes(filterText.toLowerCase())
    //             )
    //             && item.accesslog_created_at && new Date(item.accesslog_created_at) >= new Date(initFilterDate) 
    //             && new Date(item.accesslog_created_at) <= new Date(endFilterDate)
    //         )
    //     );
    // }, [initFilterDate, endFilterDate,filterText, valueSelect])

    return (
        <div className="app container">
            {/* <!-- titulo --> */}
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">{sub_title}</p>
                </section>
            </div>
            {/* <!-- Menu interno --> */}
            <nav className="child-menu">
                {menus?.map(item => {
                    return <NavLink key={item.menu_children} to={"/" + item.menu_children_action} activeclassname="active">{item.menu_children}</NavLink>
                })}
            </nav>
            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4>{t("accessLog.filters")}</h4>
                <div className="row">
                    <div className="col-md-3">
                        <BoldSelect title={t("name")} name="full_name" onChange={e => {
                            setValueSelect(e.target.value);
                            setFilterText(e.target.value);
                        }} value={valueSelect} options={filters.full_name}></BoldSelect>
                    </div>
                    <div className="col-md-3">
                        <input
                            className="form-control"
                            type="datetime-local"
                            name="cashin_date_init"
                            id="cashin_date_init"
                            onChange={e => setInitFilterDate(e.target.value)}
                            value={initFilterDate}
                            title={t("accessLog.start date")}
                        />
                    </div>
                    <div className="col-md-3">
                        <input
                            className="form-control"
                            type="datetime-local"
                            name="cashin_date_end"
                            id="cashin_date_end"
                            onChange={e => setEndFilterDate(e.target.value)}
                            value={endFilterDate}
                            title={t("accessLog.final date")}
                        />
                    </div>
                    <div className="col-md-2">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>
                    <div className="col-md-1 d-flex justify-content-end">
                        {permissions?.indexOf("create") !== -1 && (
                            <button className="btn-table-actions" onClick={() => { handlerAction(null, 'create') }}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                        )}
                        <button className="btn-table-actions">
                            <span className="material-symbols-outlined clear" onClick={() => {
                                setFilterText("");
                                setEndFilterDate("");
                                setInitFilterDate("");
                                setValueSelect("")
                            }}>cleaning_services</span>
                        </button>
                        <button className="btn-table-actions" onClick={() => { handlerAction(null, 'refresh') }}>
                            <span className="material-symbols-outlined">autorenew</span>
                        </button>
                    </div>
                </div>
            </section>
            {/* <!-- Tabla --> */}

            <DataTable
                columns={columns}
                data={
                    filteredItems.toSorted((a, b) => {
                        return b.accesslog_id - a.accesslog_id; // Orden descendente de actionlog_id (números)
                    })
                }
                pagination
                paginationResetDefaultPage={resetPaginationToggle}// optionally, a hook to reset pagination to page 1
                // subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                selectableRows
                persistTableHead
            />
        </div>
    )
}

export default AccessLog;