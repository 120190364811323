import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import BoldSelect from "../../../utils/BoldSelect";
import Swal from "sweetalert2";

const ActionLog = () => {
    const endpoint = "actionlog";
    const parent = "Logs";
    const [t] = useTranslation("global");
    const title = t('actionLog.title');
    const sub_title = t('actionLog.sub_title');
    const prefix = "actionlog";
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    const { app } = useContext(AppContext);
    const [filters, setFilters] = useState({
        serial: [],
        type: [],
    });

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        sendRequest();
    }, [])

    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                deleteRow(id, action);
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                sendRequest();
                break;
            default:
                break;
        }
    }

    const optionRequest = (id, action) => {
        const confirmButtonColor = action === 'active' ? 'green' : 'red';
        const confirmationMessage = action === 'active' ? t('activateAlert.activateRecord') : t('activateAlert.deactivateRecord');
        Swal.fire({
            title: confirmationMessage,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: t('activateAlert.confirm'),
            confirmButtonColor: confirmButtonColor,
            cancelButtonText: t('cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                const status = action === 'active' ? 1 : 0;
                const body = { action: 'updateStatus', status };
                handlerApp.setLoader(true);
                api.put(endpoint + "/" + id, body)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            sendRequest();
                        } else {
                            handlerApp.handlerResponse(response);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                    })
                    .finally(() => {
                        handlerApp.setLoader(false);
                    });
            }
        });
    };

    const deleteRow = (id) => {
        Swal.fire({
            title: `${t('deleteAlert.deleteRecord')}`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `${t('deleteAlert.delete')}`,
            confirmButtonColor: "red",
            cancelButtonText: `${t('cancel')}`
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                api.deleteMethod(endpoint + '/' + id)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            sendRequest();
                        } else {
                            handlerApp.handlerResponse(response);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                    })
                    .finally(() => {
                        handlerApp.setLoader(false);
                    });
            }
        });
    };

    const columns = [
        {
            id: 'actionlog_id',
            name: 'id',
            selector: row => row.actionlog_id,
            sortable: true,
            width: "70px"
        },
        {
            id: 'machine_serial',
            name: t('actionLog.machine'),
            selector: row => row.machine_serial + " - " + (row.machine_label || 'N/A'),
            sortable: true
        },
        {
            id: 'actionlog_type',
            name: t('actionLog.type'),
            selector: row => row.actionlog_type,
            sortable: true
        },
        {
            id: 'actionlog_event',
            name: t('actionLog.event'),
            selector: row => row.actionlog_event,
            sortable: true
        },
        {
            name: t("action"),
            cell: (row) => { return handlerApp.actions(row, permissions, prefix, handlerAction) },
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    const uniqueSerialOptions = (data) => {
        const uniqueOptions = [];
        const seen = new Set();

        data.forEach((item) => {
            const option = `${item.machine_serial} - ${item.machine_label || 'N/A'}`;

            if (!seen.has(option)) {
                uniqueOptions.push(option);
                seen.add(option);
            }
        });

        return uniqueOptions;
    };
    const uniqueTypeOptions = (data) => {
        const uniqueType = [];
        const seen = new Set();

        data.forEach((item) => {
            const option = `${item.actionlog_type || 'N/A'}`;

            if (!seen.has(option)) {
                uniqueType.push(option);
                seen.add(option);
            }
        });

        return uniqueType;
    };

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
            .then(response => {

                if (response.success) {
                    // Obtén las opciones únicas de machine_serial y machine_label
                    const uniqueOptions = uniqueSerialOptions(response.data);
                    const uniqueType = uniqueTypeOptions(response.data);

                    // Reformatea las opciones para que coincidan con el formato del BoldSelect
                    const formattedOptions = uniqueOptions.map((option) => ({
                        code: option,
                        name: option,
                    }));
                    const formattedType = uniqueType.map((option) => ({
                        code: option,
                        name: option,
                    }));
                    // Actualiza el estado de los filtros con las opciones formateadas

                    setFilters({
                        ...filters,
                        serial: formattedOptions,
                        type: formattedType
                    });

                    setData(response.data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const [filterText, setFilterText] = useState('');
    const [filterTextType, setFilterTextType] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    
    // const filteredItems = data?.filter(  // filtro anrerior
    //     item =>
    //         item.actionlog_id && item.actionlog_id.toString().toLowerCase().includes(filterText.toLowerCase()) ||
    //         (item.machine_serial + " - " + (item.machine_label || 'N/A')).toLowerCase().includes(filterText.toLowerCase()) ||
    //         item.actionlog_type?.toLowerCase().includes(filterTextType.toLowerCase()) ||
    //         item.actionlog_event?.toLowerCase().includes(filterText.toLowerCase())
    // );

    const filteredItems = data?.filter(
        item => {
            const searchText = filterText.toLowerCase();
            const searchTextType = filterTextType.toLowerCase();

            const matchesFilterText =
                (item.actionlog_id && item.actionlog_id.toString().toLowerCase().includes(searchText)) ||
                ((item.machine_serial + " - " + (item.machine_label || 'N/A')).toLowerCase().includes(searchText)) ||
                (item.actionlog_event?.toLowerCase().includes(searchText));

            const matchesFilterTextType = item.actionlog_type?.toLowerCase().includes(searchTextType);

            return matchesFilterText && matchesFilterTextType;
        }
    );
    
    return (
        <div className="app container">
            {/* <!-- titulo --> */}
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">{sub_title}</p>
                </section>
            </div>
            {/* <!-- Menu interno --> */}
            <nav className="child-menu">
                {menus?.map(item => {
                    return <NavLink key={item.menu_children} to={"/" + item.menu_children_action} activeclassname="active">{item.menu_children}</NavLink>
                })}
            </nav>
            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4>{t("accessLog.filters")}</h4>
                <div className="row">
                    <div className="col-md-4">
                        <BoldSelect title={t("actionLog.machine")} name="serial" onChange={e => setFilterText(e.target.value)} value="" options={filters.serial}></BoldSelect>
                    </div>
                    <div className="col-md-4">
                        <BoldSelect title={t("actionLog.type")} name="type" onChange={e => setFilterTextType(e.target.value)} value={filterTextType} options={filters.type}></BoldSelect>
                    </div>
                    <div className="col-md-3">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>
                    <div className="col-md-1 d-flex justify-content-end">
                        {permissions?.indexOf("create") !== -1 && (
                            <button className="btn-table-actions" onClick={() => { handlerAction(null, 'create') }}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                        )}
                        <button className="btn-table-actions" onClick={() => { handlerAction(null, 'refresh') }}>
                            <span className="material-symbols-outlined">autorenew</span>
                        </button>
                    </div>


                </div>
            </section>
            {/* <!-- Tabla --> */}
            <DataTable
                columns={columns}
                data={
                    filteredItems.toSorted((a, b) => {
                        return b.actionlog_id  - a.actionlog_id ;
                    })
                }
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                // subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                selectableRows
                persistTableHead
            />
        </div>
    )
}

export default ActionLog;