import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Logout = () => {
    const nav = useNavigate();
    
    const closeSession = ()=>{
        localStorage.removeItem("username");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("numberImformation");
        nav("/authenticate");
    }
    return {closeSession}
}

export const CheckLogin = () => {
    if (localStorage.getItem("user") === null && localStorage.getItem("token") === null && localStorage.getItem("username") === null) {
        Logout().closeSession();
    }
}



