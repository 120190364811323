import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



const MachineLocationsView = () => {
    const endpoint = "machinelocations";
    const [t, i18n] = useTranslation("global");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState({});
    const [title, setTitle] = useState("");
    const nav = useNavigate();
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState("cash");

    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
            .then(response => {
                if (response.success) {
                    handlerApp.setLoader(false);
                    let data = response.data[0];
                    setTitle(data.machine_serial);
                    setData(data);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }, [])

    const back = () => {
        nav(-1);
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }

    // Analiza data.action_logs y asigna el resultado a actionLogsArray
    let actionLogsArray = [];
    if (data.action_logs) {
        try {
            actionLogsArray = JSON.parse(data.action_logs);
        } catch (error) {
            // Manejar el error de análisis JSON si es necesario
            console.error("Error al analizar JSON:", error);
        }
    }
    return (
        <div className="app">
            <div className="container bold-container-content">
                <span className="material-symbols-outlined absolute" role="button" onClick={back}>arrow_back</span>

                <div className="title-section-forms">
                    {title}
                </div>
                <div className="row np-row">

                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <dt>{t('machines.serial')}</dt>
                                <dd>{data.machine_serial}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('machines.label')}</dt>
                                <dd>{data.machine_label}</dd>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <dt>{t('machines.location')}</dt>
                                <dd>{data.location_name}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('machines.Profit')}</dt>
                                <dd>{handlerApp.machineDiff(data.cash_in_total - data.cash_out_total)}</dd>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <dt>{t('machines.cashin')}</dt>
                                <dd>{handlerApp.machineDiff(data.cash_in_total)}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('machines.cashout')}</dt>
                                <dd>{handlerApp.machineOut(data.cash_out_total)}</dd>
                            </div>
                        </div>
                    </div>
                    <nav className="child-menu">
                        <a href="#" className={activeTab === "cash" ? "active" : ""} onClick={() => setActiveTab("cash")}>Cash</a>
                        <a href="#" className={activeTab === "cash log" ? "active" : ""} onClick={() => setActiveTab("cash log")}>Actions Log</a>
                    </nav>

                    {activeTab === "cash" && (
                        <div className="col-md-12">
                            <div className="row">
                            <div className="col-md-6">
                                <div className="col-md-12 mb-2 mt-4">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 300 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                        <StyledTableCell className="table-header">{t('machines.cashin')}</StyledTableCell>
                                        <StyledTableCell align="right" className="table-header">{t('machines.date')}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.cash_in?.map((row, index) => (
                                        <StyledTableRow key={"cashin-" + index}>
                                            <StyledTableCell component="th" scope="row">
                                            {handlerApp.formatCurrency(row.cashin_in)}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                            {handlerApp.getDate(row.cashin_date)}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        ))}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="col-md-12 mb-2 mt-4">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 300 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell className="table-header">{t('machines.cashout')}</StyledTableCell>
                                            <StyledTableCell align="right" className="table-header">{t('machines.date')}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.cash_out?.map((row, index) => (
                                        <StyledTableRow key={"cashout-" + index}>
                                            <StyledTableCell component="th" scope="row">
                                            {handlerApp.formatCurrency(row.cashout_out)}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                            {handlerApp.getDate(row.cashout_date)}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        ))}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                                </div>
                            </div>
                            </div>
                        </div>
                    )}

                    {activeTab === "cash log" && (
                        <div className="col-md-12">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 mb-2 mt-4">
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 300 }} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell className="table-header">{t('machines.ActionType')}</StyledTableCell>
                                                        <StyledTableCell className="table-header">{t('machines.ActionEvent')}</StyledTableCell>
                                                        <StyledTableCell className="table-header">{t('machines.ActionCreatedAt')}</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {actionLogsArray.map((row) => (
                                                        <StyledTableRow key={row.actionlog_id}>
                                                            <StyledTableCell>{row.actionlog_type}</StyledTableCell>
                                                            <StyledTableCell>{row.actionlog_event}</StyledTableCell>
                                                            <StyledTableCell>{handlerApp.getDate(row.actionlog_created_at)}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    )}

                    <div className="footer-section-view row">
                        <span className="divisor"></span>
                        <div className="col-md-6">
                            <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                        </div>
                        <div className="col-md-6">
                            <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                        </div>
                        <div className="col-md-6">
                            {data.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                        </div>
                        <div className="col-md-6">
                            {data.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default MachineLocationsView;