import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import env from "../env-local";
import HandlerApp from "../utils/handlerApp";


export const useFetch = () => {
    const instance = axios.create({
        baseURL: env.urlBackend,
        headers: {
            Authorization: (localStorage.getItem("token")) ? localStorage.getItem("token") : ""
        }
    })
    const handlerApp = HandlerApp();
    const nav = useNavigate();
    const logout = () => {
        localStorage.clear();
        Swal.fire({
            title: "",
            text: "Session Expired",
            icon: "info"
        });
    }

    const notPermissions = () => {
        Swal.fire({
            title: "",
            text: "Not permission",
            icon: "warning"
        });
        handlerApp.setLoader(false);
        nav("/");
    }

    const post = async (url, body = {}, params = "") => {
        params = new URLSearchParams(params).toString();
        url = (params !== "") ? url + "?" + params : url;
        return new Promise((resolve, reject) => {
            instance.defaults.timeout = 480000;
            instance.post(url, body)
                .then(response => {
                    if (response.data.success) {
                        resolve(response.data);
                    } else {
                        if (response.data.action === "closeSession") {
                            logout()
                            nav("/authenticate");
                        } else if (response.data.code === "0001") {
                            notPermissions();
                        } else {
                            resolve(response.data);
                        }
                    }
                }).catch(error => {
                    reject(error.response.data);
                })
        });
        // TODO eliminar comentarios
        // const response = await instance.post(url, body)
        // return new Promise((resolve,reject)=>{
        //     if (response.data.success) {
        //         resolve(response.data);
        //     } else {
        //         if (response.data.action === "closeSession") { // no return
        //             logout()
        //             nav("/authenticate");
        //         }else if(response.data.code === "0001"){
        //             notPermissions();
        //         }else {
        //             resolve(response.data);
        //         }
        //     }
        // });
    }

    const put = async (url, body = {}, params = "") => {
        params = new URLSearchParams(params).toString();
        url = (params !== "") ? url + "?" + params : url;
        return new Promise((resolve, reject) => {
            try {
                instance.defaults.timeout = 480000;
                instance.put(url, body)
                    .then(response => {
                        if (response.data.success) {
                            resolve(response.data);
                        } else {
                            if (response.data.action === "closeSession") {
                                logout()
                                nav("/authenticate");
                            } else if (response.data.code === "0001") {
                                notPermissions();
                            } else {
                                resolve(response.data);
                            }
                        }
                    }).catch(error => {
                        reject(error.response.data);
                    })
            } catch (error) {
                reject(error.response.data);
            }

        });

        // const response = await instance.put(url, body);
        // return new Promise((resolve,reject)=>{
        //     if (response.data.success) {
        //         resolve(response.data);
        //     } else {
        //         if (response.data.action === "closeSession") { // no return
        //             logout()
        //             nav("/authenticate");
        //         }else if(response.data.code === "0001"){
        //             notPermissions();
        //         }else {
        //             resolve(response.data);
        //         }
        //     }
        // });
    }

    const get = async (url, params) => {

        params = new URLSearchParams(params).toString();
        url = (params !== "") ? url + "?" + params : url;
        return new Promise((resolve, reject) => {
            instance.defaults.timeout = 480000;
            instance.get(url)
                .then(response => {
                    if (response.data.success) {
                        resolve(response.data);

                    } else {
                        if (response.data.action === "closeSession") {
                            logout()
                            nav("/authenticate");
                        } else if (response.data.code === "0001") {
                            notPermissions();
                        } else {
                            resolve(response.data);
                        }
                    }
                }).catch(error => {
                    reject(error.response.data);
                })
        });

        // instance.get(url)
        // .then(response =>{
        //     console.log(response);
        // })
        // .catch(error=>{
        //     console.log(error);
        // })
        // console.log(response.data);
        // return new Promise((resolve,reject)=>{
        //     if (response.data.success) {
        //         resolve(response.data);
        //     } else {
        //         if (response.data.action === "closeSession") {
        //             logout()
        //             nav("/authenticate");
        //         }else if(response.data.code === "0001"){
        //             notPermissions();
        //         }else {
        //             resolve(response.data);
        //         }
        //     }
        // });
    }
    const deleteMethod = async (url, body = {}, params = "") => {
        params = new URLSearchParams(params).toString();
        url = (params !== "") ? url + "?" + params : url;
        return new Promise((resolve, reject) => {
            instance.defaults.timeout = 480000;
            instance.delete(url, body)
                .then(response => {
                    if (response.data.success) {
                        resolve(response.data);
                    } else {
                        if (response.data.action === "closeSession") {
                            logout()
                            nav("/authenticate");
                        } else if (response.data.code === "0001") {
                            notPermissions();
                        } else {
                            resolve(response.data);
                        }
                    }
                }).catch(error => {
                    reject(error.response.data);
                })
        });

        // const response = await instance.delete(url, body);
        // return new Promise((resolve,reject)=>{
        //     if (response.data.success) {
        //         resolve(response.data);
        //     } else {
        //         if (response.data.action === "closeSession") { // no return
        //             logout()
        //             nav("/login");
        //         }else if(response.data.code === "0001"){
        //             notPermissions();
        //         }else {
        //             resolve(response.data);
        //         }
        //     }
        // });
    }

    return { post, put, get, deleteMethod, nav };
}