import { createContext, useEffect, useState } from "react";

const AppContext = createContext();
const AppContextProvider = ({ children }) => {

    const [app,setApp] = useState({
        permissions: [],
        menus: [],
        language: 'EN',
        currency: 'USD',
    });

    // useEffect(()=>{
    //     console.log("aca"); 
    //     console.log(app);
    // },[app])

    // const [values, setValue] = useState({
    //     light: {
    //         foreground: '#000000',
    //         background: '#eeeeee',
    //     },
    //     dark: {
    //         foreground: '#ffffff',
    //         background: '#222222',
    //     },
    // });

    return (
        <AppContext.Provider value={{app,setApp}}>
            {children}
        </AppContext.Provider>
    )
}

export { AppContext, AppContextProvider };