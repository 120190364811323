import './../../assets/css/App.css';
import Widget from '../molecules/Widget';
import { useFetch } from '../../hooks/useFecth';
import HandlerApp from '../../utils/handlerApp';
import { useEffect, useState } from 'react';

function Home() {

  const endpoint = "home";
  const api = useFetch();
  const handlerApp = HandlerApp();
  const [data, setData] = useState([])

  useEffect(() => {
    handlerApp.setLoader(true);
    api.get(endpoint)
    .then(response => {
        if (response.success) {
            setData(response.data[0])
            handlerApp.setLoader(false);
        } else {
            handlerApp.setLoader(false);
        }
    })
    .catch(error => {
        handlerApp.setLoader(false);
        handlerApp.showError(error.message);
    })
}, [])
  
  return (
    <div className="app">
      <div className='row'>
        <div className='col-xl-3 col-lg-4 col-md-6'>
          <Widget idWidget="1" title="Users" icon="person" value={ (data.cantidadusers) ? data.cantidadusers : 0} compareColor="green" />
        </div>
        <div className='col-xl-3 col-lg-4 col-md-6'>
          <Widget idWidget="2" title="Machines" icon="rocket_launch" value={ (data.cantidadmachines) ? data.cantidadmachines : 0 }  compareColor="orange" />
        </div>
        <div className='col-xl-3 col-lg-4 col-md-6'>
          <Widget idWidget="4" title="Ingresos" icon="attach_money" value={handlerApp.formatCurrency(data.cashinsum-data.cashoutsum)}  compareColor="green" />
        </div>
        <div className='col-xl-3 col-lg-4 col-md-6'>
          <Widget idWidget="3" title="Sucursales" icon="browse_activity" value={ (data.locationsum) ? data.locationsum : 0}  compareColor="red" />
        </div>
      </div>
    </div>
  );
}

export default Home;
